import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="next slick-arrow" style={style}>
      <i className="fal fa-long-arrow-right" />
      <FontAwesomeIcon icon={faLongArrowAltRight} />
    </span>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <span onClick={onClick} className="prev slick-arrow" style={style}>
      <FontAwesomeIcon icon={faLongArrowAltLeft} />
    </span>
  );
}

class Register extends React.Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1201,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div className="asnwers-area pt-50 pb-100 gray-bg">
        <Container>
          <Row>
            <Col lg="6">
              <div className="section-title-2">
                <h2 className="title">
                  Registration of all types of legal entities
                </h2>
                <p>
                  [Needed] Does any industry face a more complex audience
                  journey and marketing sales process than B2B technology.
                </p>
              </div>
              {/* section title 2 */}
              <div className="faq-accordion-2 mt-30">
                <Accordion defaultActiveKey="0">
                  <Card
                    className="wow fadeInLeft"
                    data-wow-duration=".5s"
                    data-wow-delay=".3s"
                  >
                    <Card.Header>
                      <Accordion.Toggle as={Link} eventKey="0">
                        Official partners
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p>
                          We are the official partners of the free zones
                          of Dubai, we carry out the registration of all types
                          of legal entities in the land of Dubai
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {/* card */}
                  <Card
                    className="wow fadeInLeft"
                    data-wow-duration=".6s"
                    data-wow-delay=".4s"
                  >
                    <Card.Header>
                      <Accordion.Toggle as={Link} eventKey="1">
                        Over the years, a wide range of developments
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p>
                          Today, the term Information Technology (IT) has
                          ballooned to encompass is real. Today, the term
                          Information Technology (IT) has ballooned to encompass
                          is real.Today, the term Information Technology (IT)
                          has ballooned to encompass is real.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {/* card */}
                  <Card
                    className="wow fadeInLeft"
                    data-wow-duration=".7s"
                    data-wow-delay=".5s"
                  >
                    <Card.Header id="headingThree">
                      <Accordion.Toggle as={Link} eventKey="2">
                        As a result, most of us need to know
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <p>
                          Today, the term Information Technology (IT) has
                          ballooned to encompass is real. Today, the term
                          Information Technology (IT) has ballooned to encompass
                          is real.Today, the term Information Technology (IT)
                          has ballooned to encompass is real.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {/* card */}
                  <Card
                    className="wow fadeInLeft"
                    data-wow-duration=".8s"
                    data-wow-delay=".6s"
                  >
                    <Card.Header id="headingFour">
                      <Accordion.Toggle as={Link} eventKey="3">
                        Our knowledge of computers will help us
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <p>
                          Today, the term Information Technology (IT) has
                          ballooned to encompass is real. Today, the term
                          Information Technology (IT) has ballooned to encompass
                          is real.Today, the term Information Technology (IT)
                          has ballooned to encompass is real.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {/* card */}
                  <Card
                    className="wow fadeInLeft"
                    data-wow-duration=".9s"
                    data-wow-delay=".7s"
                  >
                    <Card.Header id="headingFive">
                      <Accordion.Toggle as={Link} eventKey="4">
                        Our knowledge of computers will help us
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        <p>
                          Today, the term Information Technology (IT) has
                          ballooned to encompass is real. Today, the term
                          Information Technology (IT) has ballooned to encompass
                          is real.Today, the term Information Technology (IT)
                          has ballooned to encompass is real.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {/* card */}
                </Accordion>
              </div>
              {/* faq accordion */}
            </Col>
            <Col lg="6">
              <div className="answers-thumb">
                <div className="answers-thumb-1 text-right">
                  <img src="assets/images/answers-thumb-1.jpg" alt="" />
                </div>
                <div className="answers-thumb-2">
                  <img src="assets/images/answers-thumb-2.jpg" alt="" />
                </div>
              </div>
              {/* answers thumb */}
            </Col>
          </Row>
          {/* row */}
        </Container>
        {/* containter */}
      </div>
    );
  }
}

export default Register;
