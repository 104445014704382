import React from 'react';
import ModalVideo from 'react-modal-video'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';
import ContactUs from '../../componets/contactUs/Index';

class ContactPage extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  // componentDidMount() {
	// 	setTimeout(function () {
	// 		document.querySelector(".preloader").style = "display: none";
	// 	}, 5000);
  // }
  openModal = event => {
    this.setState({isOpen: true});
    event.preventDefault();
  }

  formHandle = event => {
    toast.success("Thank you for contact with us!");
    event.preventDefault();
  }

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title">Contact Us</h2>
                  <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Contact Us
                      </li>
                    </ol>
                  </nav>
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }
        <div>
          {/*====== CONTACT DETAILS PART START ======*/}
       
          {/*====== CONTACT DETAILS PART ENDS ======*/}
          {/*====== GET IN TOUCH PART START ======*/}
          <ContactUs/>
          <div className="contact-details-area pt-90 pb-120">
            <Container>
              <Row>
                <Col lg="6">
                  <div className="contact-info mr-30 mt-30">
                    <div className="contact-item-1">
                      <div className="contact-info-item text-center">
                        <i className="fal fa-phone" />
                        <h5 className="title">Phone Number</h5>
                        <p>+971 50 823 6155</p>
                      </div>
                      <div className="contact-info-item text-center">
                        <i className="fal fa-envelope" />
                        <h5 className="title">Email Address</h5>
                        <p>Bestbguae@gmail.com</p>
                      </div>
                    </div>
                    <div className="contact-item-1">
                      <div className="contact-info-item text-center">
                        <i className="fal fa-map" />
                        <h5 className="title">Office Location</h5>
                        <p>Dubai</p>
                      </div>
                      <div className="contact-info-item text-center">
                        <i className="fal fa-globe" />
                        <h5 className="title">Social Network</h5>
                        {/* <p>fb.com/example</p> */}
                      </div>
                    </div>
                  </div>
                  {/* contact info */}
                </Col>
                {/* <Col lg="6">
                  <div className="map-area mt-30">
                    <iframe
                      src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7496149.95373021!2d85.84621250756469!3d23.452185887261447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30adaaed80e18ba7%3A0xf2d28e0c4e1fc6b!2sBangladesh!5e0!3m2!1sen!2sbd!4v1569913375800!5m2!1sen!2sbd"
                      style={{ border: 0 }}
                      allowFullScreen
                      width={600}
                      height={450}
                      title="Our Localtion"
                    />
                  </div> */}
                  {/* map area */}
                {/* </Col> */}
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
          
        </div>
        </Layout>
      );
    }
}
 
export default ContactPage;