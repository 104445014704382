export const data = [
  {
    name: "Freelance visa",
    monthly_price: "9500",
    // yearly_price: "99",
    currency: "AED",
    description: "Freelance visa  from 9500 within 7 working days.",
    action: {
      name: "Purchase Now",
      url: "/",
    },
  },
  {
    name: "Partner visa",
    monthly_price: "15000",
    // yearly_price: "199",
    currency: "AED",
    description: "Partner visa from 15000 within 5 to 7 working days.",
    action: {
      name: "Purchase Now",
      url: "/",
    },
  },
  {
    name: "Golden visa",
    monthly_price: "16000",
    // yearly_price: "999",
    currency: "AED",
    description: "Golden visa from 16000 with in 10 working days.",
    action: {
      name: "Purchase Now",
      url: "/",
    },
  },
  {
    name: "Company formation in Free-zone/MainLand",
    monthly_price: "19000",
    yearly_price: "1039",
    currency: "AED",
    description:
      "Company formation in Free-zone/MainLand from 19000 within 7-14 days.",
    action: {
      name: "Purchase Now",
      url: "/",
    },
  },
  {
    name: "Tax/Accounting",
    monthly_price: "500",
    yearly_price: "",
    currency: "AED",
    description:
      "Tax/Accounting with low consulting fee from 500 aed. First 15 minutes consultation free of cost. ",
    action: {
      name: "Purchase Now",
      url: "/",
    },
  },
  // {
  //   name: "Vat certification",
  //   monthly_price: "",
  //   yearly_price: "",
  //       currency: "AED",
  //   description:
  //     "Vat certification, import/customs code with attractive prices.",
  //   action: {
  //     name: "Purchase Now",
  //     url: "/",
  //   },
  // },
  {
    name: "Family visa",
    monthly_price: "8000",
    yearly_price: "",
    currency: "AED",
    description: "Family visa From 8000 within 5 to 7 working days.",
    action: {
      name: "Purchase Now",
      url: "/",
    },
  },
  {
    name: "Employment visa",
    monthly_price: "6000",
    yearly_price: "",
    currency: "AED",
    description: "Employment visa 6000 onwards within 7 working days.",
    action: {
      name: "Purchase Now",
      url: "/",
    },
  },
];

export default data;
