import React from "react";
import {
  Container,
  Button,
  Link,
  lightColors,
  darkColors,
} from "react-floating-action-button";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NavigationIcon from "@mui/icons-material/Navigation";
const flottingAction = () => {
  return (
    <Container className="z-100">
      {/* <Link
        href="#"
        tooltip="Create note link"
        icon="far fa-sticky-note"
        styles={{ backgroundColor: darkColors.blue, color: lightColors.white }}
      />
      <Link
        href="#"
        styles={{ backgroundColor: darkColors.blue, color: lightColors.white }}
        tooltip="Add user link"
        icon="fa fa-whatsapp fa-2x"
        className="fab-item btn btn-link btn-lg text-white"
      /> */}
      {/* <FontAwesomeIcon icon="fa-brands fa-square-whatsapp" style={{color: "#000000",}} /> */}
      {/* <Button
        styles={{ backgroundColor: darkColors.blue, color: lightColors.white }}
        tooltip="Whats App"
        icon="fas fa-comment fa-2x"
        rotate={false}
        onClick={() => {
          let url = `https://web.whatsapp.com/send?phone=+971 50 823 6155`; 
          url += `&text=${encodeURI("Hey, I need a consultation")}&app_absent=0`;
          window.open(url);
        }}
      /> */}
      <Button
        onClick={() => {
          let url = `https://web.whatsapp.com/send?phone=+971 0555621879`;
          url += `&text=${encodeURI(
            "Hey, I need a consultation"
          )}&app_absent=0`;
          window.open(url); 
        }}
      >
        <Fab color="primary" aria-label="add" size='large'>
          <WhatsAppIcon />
        </Fab>
      </Button>
    </Container>
  );
};
export default flottingAction;
