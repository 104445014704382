// export const Reasons={
// "CompanyFormation": "Company formation",
// "Visas":"Visas" ,
// "RealEstates":"Real estate’s" ,
// "Investment":"Investment",
// "EventManagements":"Event managements",
// "BankAccounts" :"Bank accounts for both personal and Businesses",
// "Other":"Other"
// }
export const servicesData = [
    "Company Formation",
    "Visas",
    "Real Estate",
    "Investment/Insurance",
    "Event management",
    "Bank accounts for both personal and Business",
    "Property Mortgage-Loan",
    "General Insurance",
    "Complete Business solutions",
    "Accounting and Tax consulting",
    // "Tourist/Visit Visa",
    // "Cheap Flight & Hotel Bookings"
  ];