import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyDLf35vh16tcxJMcJVqzyn8Iit3refZmqo",
    authDomain: "dev-bestbusiness.firebaseapp.com",
    projectId: "dev-bestbusiness",
    storageBucket: "dev-bestbusiness.appspot.com",
    messagingSenderId: "77536420452",
    appId: "1:77536420452:web:1ea9234a78e304a6177571",
    measurementId: "G-T5TMEQ4E1D",
  };
  export const fireBaseApp = initializeApp(firebaseConfig);

  