import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class ServicesItemPart extends React.Component {
    render() { 
        return ( 
          <div className="services-item-area pt-90 pb-120">
            <Container>
            <Row>
                <div className="col-lg-7 col-md-7">
                  <div className="section-title-2">
                    <h2 className="title">Investment Services</h2>
                    <p>
                      [Needed]Structured notes and life insurance. We will offer the best investment programs to guarantee the growth of your wealth!
                    </p>
                  </div>
                  {/* section title 2 */}
                </div>
              </Row>
              <Row className="justify-content-center">
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-1.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-eye-dropper" />
                      <h4 className="title">Investment in real estate</h4>
                      <p>
                        The functional goal of technical content is to help people use a
                        product successfully. The business goal must tie the content.
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-2.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-gavel" />
                      <h4 className="title">Heritage planning</h4>
                      <p>
                        The functional goal of technical content is to help people use a
                        product successfully. The business goal must tie the content.
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-3.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-map-marked-alt" />
                      <h4 className="title">Retirement planning</h4>
                      <p>
                        The functional goal of technical content is to help people use a
                        product successfully. The business goal must tie the content.
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
             
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
         );
    }
}
 
export default ServicesItemPart;