import React from 'react';
import PartialNav from './PartialNav';
import { Link } from "react-router-dom";
import SocialLink from './SocialLink';
// import Logo from '../../../assets/images/logo-2.png';
import Logo from '../../../assets/images/main-logo.png';

import { Row, Col, Navbar } from 'react-bootstrap';
// import { FiMail, FiSmartphone, FiShoppingCart } from "react-icons/fi";


class Header extends React.Component {

    render() {
        return (
            <>
           <header className="header-area header-area-2">
            <div className="header-nav">
              <div className="navigation">
                <Navbar expand="lg" className={`transparent-color`}>
                  <Navbar.Brand as={Link} to={'/'}>
                  <img height={80} width={80} src={Logo} className='rounded-circle' alt="" />
                  </Navbar.Brand>
                  <Navbar.Toggle>
                    <span className="toggler-icon" />
                    <span className="toggler-icon" />
                    <span className="toggler-icon" />
                  </Navbar.Toggle> {/* navbar toggler */}
                  <Navbar.Collapse className="sub-menu-bar"  id="navbarSupportedContent">
                    <PartialNav/>
                  </Navbar.Collapse> {/* navbar collapse */}
                  {/* <div className="bar-area d-none d-xl-block"> */}
                    {/* <ul> */}
                      {/* <li><Link to={"shop"}><FiShoppingCart/></Link></li> */}
                      {/* <li><Link to={"/"}><i className="fal fa-search" /></Link></li>
                      <li><Link to={"/"}><i className="fal fa-bars" /></Link></li> */}
                    {/* </ul> */}
                  {/* </div> */}    
                  <div className="navbar-btn mr-100">
                    <Link className="main-btn" to={"/contact"}>Free Consulting <i className="fal fa-long-arrow-right" /></Link>
                  </div>
                </Navbar>
              </div> {/* navigation */}
            </div>
          </header>
              </>
         );
    }
}
 
export default Header;