import React, { useRef } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index1 from "./pages/home/index1";
import Index2 from "./pages/home/index2";
import Index3 from "./pages/home/index3";
import Index4 from "./pages/home/index4";
import AboutPage from "./pages/about-draft";
import ContactPage from "./pages/contact";
import ServicesPage from "./pages/service";
import ServicesDetails from "./pages/service/details";
import CaseStudy from "./pages/case-study";
import CaseDetails from "./pages/case-study/details";
import ComingSoon from "./pages/coming-soon";
import FaqPage from "./pages/faq";
import PricingPage from "./pages/pricing";
import ShopPage from "./pages/shop";
import ShopDetailsPage from "./pages/shop/details";
import ProductPage from "./pages/product";
import ProductDetailPage from "./pages/product-details";

import TeamPage from "./pages/about";
import TeamDetailsPage from "./pages/about/details";
import BlogStandard from "./pages/blog/blog-standard";
import BlogGrid from "./pages/blog/blog-grid";
import BlogDetails from "./pages/blog/blog-details";
import BlogDetails2 from "./pages/blog/blog-details2";
import NoMatch from "./pages/errors/no-match";
import Preloader from "./componets/preloader/Index";
import ChooseUs from "./pages/chooseUs";
import ChooseUsDetails from "./pages/chooseUs/details";
import AboutDetailsPage from "./pages/about/details";

import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { fireBaseApp, firebaseConfig } from "./data/firebaseConstants";
import AdminLogin from "./pages/admin-login";
import FlottingAction from "./componets/flottingAction/index";
import Social from "./componets/social/index";
class App extends React.Component {
  render() {
    return (
      <>
        <Social />
        <FlottingAction />
        <BrowserRouter>
          <Preloader />

          <Routes>
            <Route path="/" element={<Index2 />} />
            <Route path="index" element={<Index2 />} />
            {/* <Route path="index2" element={<Index1 />} /> */}

            {/* <Route path="index3" element={<Index3 />} /> */}
            {/* <Route path="index4" element={<Index4 />} /> */}
            <Route path="chooseUs" element={<ChooseUs />} />
            <Route path="admin-login" element={<AdminLogin />} />
            <Route path="chooseUsDetails" element={<ChooseUsDetails />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="about-detail-page" element={<AboutDetailsPage />} />
            <Route path="services" element={<ServicesPage />} />
            <Route path="services-details" element={<ServicesDetails />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="coming-soon" element={<ComingSoon />} />
            {/* <Route path="case-study" element={<CaseStudy />} /> */}
            {/* <Route path="case-details" element={<CaseDetails />} /> */}
            {/* <Route path="error" element={<NoMatch />} /> */}
            {/* <Route path="faq" element={<FaqPage />} /> */}
            <Route path="pricing" element={<PricingPage />} />
            {/* <Route path="products" element={<ProductPage />} /> */}
            {/* <Route path="products-details" element={<ProductDetailPage />} /> */}
            {/* <Route path="shop" element={<ShopPage />} /> */}
            {/* <Route path="shop-details" element={<ShopDetailsPage />} /> */}
            {/* <Route path="team" element={<TeamPage />} /> */}
            {/* <Route path="team-details" element={<TeamDetailsPage />} /> */}
            {/* <Route path="blog-standard" element={<BlogStandard />} /> */}
            {/* <Route path="blog-grid" element={<BlogGrid />} /> */}
            {/* <Route path="blog-details" element={<BlogDetails />} /> */}
            {/* <Route path="blog-details2" element={<BlogDetails2 />} /> */}
            <Route element={<NoMatch />} />
          </Routes>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
