import React from "react";
import Footer from "../../layouts/partials/footer/index";
import Header from "../../layouts/partials/header/Header2";
import Banner4 from "../../componets/banner/Index4";
import Banner2 from "../../componets/banner/Index2";
import AboutPart from "../../componets/aboutPart/Index";
import WhatWeDoPart from "../../componets/whatWeDoPart/Index";
import IntroVideoPart from "../../componets/introVideoPart/Index";
import ContactUs from "../../componets/contactUs/Index";
import Action2Part from "../../componets/action2Part/Index";
import ServicesItemPart from "../../componets/servicesItemPart/Index";
import Register from "../../componets/Register/Index";


class Index2 extends React.Component {
  render() {
    return (
      <>
        <Header />
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Banner4 />
        <WhatWeDoPart />
        {/* <AboutPart /> */}

        {/* <IntroVideoPart /> */}
        {/* <Register /> */}
        {/* <ServicesItemPart /> */}
        {/* <ProgressBarPart/> */}
        {/* <QuotePart/> */}
        {/* <GetInTouchPart/> */}
        <Action2Part />
        <ContactUs />
        {/* <AnswersPart/> */}
        {/* <LatestNewsPart/> */}
        <Footer />
        {/* <FlottingAction/> */}
      </>
    );
  }
}

export default Index2;
